<template>
  <div class="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {}
};
</script>
<style lang="less" scoped>
.app {
  // min-height: 100vh;
  // background-image: url("./assets/image/background.png");
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
}
</style>