import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    redirect:'/home',
  },
  // {
  //   path: '/taber/:id',
  //   name: 'taber',
  //   component: ()=> import('../views/taber/index'),
  //   children:[
      
  //   ],
  // },
  {
    path:'/home',
    name:'home',
    component:()=> import('../views/home/index'),
  },
  {
    path:'/hotel',
    name:'hotel',
    component:()=> import('../views/hotel/index'),
  },
  {
    path:'/summit',
    name:'summit',
    component:()=> import('../views/summit/index'),
  },
  {
    path:'/plenary',
    name:'plenary',
    component:()=> import('../views/plenary/index'),
  },
  {
    path:'/information',
    name:'information',
    component:()=> import('../views/information/index'),
  },
  {
    path:'/qr',
    name:'qr',
    component:()=> import('../views/qr/index'),
  },
  {
    path:'/user',
    name:'user',
    component:()=> import('../views/user/index'),
  },
  {
    path:'/guest',
    name:'guest',
    component:()=> import('../views/guest/index'),
  },
  {
    path:'/schedule',
    name:'schedule',
    component:()=> import('../views/schedule/index'),
  },
  {
    path:'/review/:index',
    name:'review',
    component:()=> import('../views/review/index'),
  },
]

const router = new VueRouter({
  routes
})

export default router
